<template>
  <div class="d-flex">
    <DevAddressVerificationForm v-if="isSPA" class="ma-4" />
    <div class="flex-grow-1 pa-3">
      <AddressVerificationsWidgetHeader
        :is-suggestions-visible="isSuggestionsVisible"
        class="mb-4"
      />

      <ApLoading v-if="loadingText" :text="loadingText" class="py-8" />

      <ApStatus
        v-else-if="statusConfigs && !isSuggestionsVisible"
        :type="statusConfigs.type"
        :title="statusConfigs.title"
        :description="statusConfigs.description"
        :is-loading="isVerifying"
        button-label="Validate"
        button-event="verify"
        class="py-16"
        @verify="onVerify"
      />

      <ApSingleRecordCompletion
        v-if="isSuggestionsVisible"
        :props="addressProperties"
        :user-entry="address"
        :system-entry="addressSuggestion"
        @cancel="onIgnoreSuggestions"
        @submit="onSubmitAddressModified"
      />
    </div>
  </div>
</template>

<script>
import { isSPA } from '@/utils/env'
import { sendBusMessage } from '@/services/message-bus'
import ApLoading from '@/components/common/ApLoading'
import ApStatus from '@/components/common/ApStatus'
import ApSingleRecordCompletion from '@/components/common/ApSingleRecordCompletion'
import AddressVerificationsWidgetHeader from './components/AddressVerificationsWidgetHeader'
import {
  authenticateOnCleanVerify,
  getLastVerification,
  verifyAddress,
  trackAcceptedSuggestion,
  trackCancelledSuggestion,
} from '@/api/clean-verify/address-verifications'
import { mapState } from 'vuex'
import { mapToObject } from '@/api/helpers/mappers'

const CUSTOMER_ADDRESS_RESPONSE_MAPPER = {
  name: 'name',
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  city: 'city',
  stateOrProvince: 'stateorprovince',
  postalCode: 'postalcode',
  country: 'country',
}

const OTHER_ENTITIES_ADDRESS_RESPONSE_MAPPER = {
  name: 'name',
  firstName: 'firstname',
  lastName: 'lastName',
  line1: 'address1_line1',
  line2: 'address1_line2',
  line3: 'address1_line3',
  city: 'address1_city',
  stateOrProvince: 'address1_stateorprovince',
  postalCode: 'address1_postalcode',
  country: 'address1_country',
}

const CUSTOMER_ADDRESS_REQUEST_MAPPER = {
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  city: 'city',
  stateorprovince: 'stateOrProvince',
  postalcode: 'postalCode',
  country: 'country',
}

const OTHER_ENTITIES_ADDRESS_REQUEST_MAPPER = {
  address1_line1: 'line1',
  address1_line2: 'line2',
  address1_line3: 'line3',
  address1_city: 'city',
  address1_stateorprovince: 'stateOrProvince',
  address1_postalcode: 'postalCode',
  address1_country: 'country',
}

export default {
  components: {
    ApLoading,
    ApStatus,
    ApSingleRecordCompletion,
    AddressVerificationsWidgetHeader,
    DevAddressVerificationForm: () =>
      import('@/components/dev/DevAddressVerificationForm.vue'),
  },
  data() {
    return {
      isSPA,
      status: 'idle',
      verificationId: null,
      address: {},
      addressSuggestion: {},
      isVerifying: false,
      isSuggestionsVisible: false,
      loadingText: 'Getting form information...',
      lastVerificationDate: null,
      entityId: null,
    }
  },
  computed: {
    ...mapState('auth', ['contextMessage']),
    statusConfigs() {
      switch (this.status) {
        case 'valid':
          return {
            type: 'success',
            title: 'Address validated',
            description: `Last validation: ${this.lastVerificationDate}`,
          }
        case 'invalid':
          return {
            type: 'error',
            title: 'Invalid address',
            description: 'Check the address and validate again',
          }
        case 'unknown':
          return {
            type: 'warning',
            title: 'Unknown address',
            description:
              'Based on information entered, the address could not be verified.',
          }
        case 'idle':
          return {
            type: 'warning',
            title: 'This address has not been validated',
          }
        default:
          return false
      }
    },
    addressProperties() {
      const addressProperties = []

      for (const prop in this.addressSuggestion) {
        addressProperties.push({
          key: prop,
          label: this.$t(`address_verification.fields.${prop}`),
        })
      }

      return addressProperties
    },
  },
  async created() {
    this.entityId = this.contextMessage.data.entityKey
    this.entityType = this.contextMessage.data.entityName

    await authenticateOnCleanVerify({
      userId: this.contextMessage.userId,
      totp: this.contextMessage.totp,
    })

    this.getLastHitInformation()

    sendBusMessage({
      action: 'ready',
      source: 'WebUI',
    })

    window.addEventListener(
      'message',
      async (event) => {
        const { data: { data: { inputQuery } = {} } = {} } = event || {}

        if (inputQuery) {
          this.isSuggestionsVisible = false
          this.address = mapToObject(
            inputQuery,
            this.entityType === 'customeraddress'
              ? CUSTOMER_ADDRESS_RESPONSE_MAPPER
              : OTHER_ENTITIES_ADDRESS_RESPONSE_MAPPER
          )
        }
      },
      false
    )
  },
  methods: {
    async getLastHitInformation() {
      this.loadingText = 'Getting last verification date...'

      const lastHit = await getLastVerification(this.entityId, this.entityType)

      if (lastHit) {
        this.status = lastHit.status
        this.lastVerificationDate = lastHit.date
      }

      this.loadingText = ''
    },
    async onVerify() {
      this.isVerifying = true
      try {
        const { status, verificationId, suggestion } = await verifyAddress({
          userId: this.contextMessage.userId,
          userName: this.contextMessage.userName,
          entityId: this.entityId,
          entityType: this.entityType,
          recordName: this.buildRecordName(),
          formData: this.address,
        })

        this.verificationId = verificationId
        this.status = status

        const lastHit = await getLastVerification(
          this.entityId,
          this.entityType
        )

        this.lastVerificationDate = lastHit.date

        if (status === 'valid') {
          this.addressSuggestion = suggestion
          this.isSuggestionsVisible = true
        }
      } finally {
        this.isVerifying = false
      }
    },
    buildRecordName() {
      switch (this.entityType) {
        case 'contact':
        case 'lead':
          return this.address.firstName + ' ' + this.address.lastName
        case 'account':
        case 'customeraddress':
          return this.address.name
        default:
          return ''
      }
    },
    onIgnoreSuggestions() {
      trackCancelledSuggestion(this.verificationId)

      this.isSuggestionsVisible = false
    },
    onSubmitAddressModified(finalRecord) {
      trackAcceptedSuggestion(this.verificationId)

      this.address = { ...finalRecord }

      sendBusMessage({
        action: 'updateAndSaveForm',
        source: 'WebUI',
        fields: mapToObject(
          finalRecord,
          this.entityType === 'customeraddress'
            ? CUSTOMER_ADDRESS_REQUEST_MAPPER
            : OTHER_ENTITIES_ADDRESS_REQUEST_MAPPER
        ),
      })
      this.isSuggestionsVisible = false
    },
  },
}
</script>

<template>
  <div class="text-center pa-3">
    <div>
      <v-icon small :color="configs.iconColor" class="mr-2">
        {{ configs.icon }}
      </v-icon>
      <span class="text-caption font-weight-medium" :class="configs.titleColor">
        {{ title }}
      </span>
    </div>

    <div v-if="description" class="text-caption ap-dark-gray--text">
      {{ description }}
    </div>

    <v-btn
      color="primary"
      class="mt-2"
      :loading="isLoading"
      @click="$emit(buttonEvent)"
    >
      {{ buttonLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'warning',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    buttonEvent: {
      type: String,
      default: 'click',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    configs() {
      switch (this.type) {
        case 'success':
          return {
            titleColor: 'ap-green--text',
            iconColor: 'green',
            icon: '$mdi-check',
          }
        case 'error':
          return {
            titleColor: 'ap-red--text',
            iconColor: 'red',
            icon: '$mdi-alert-circle-outline',
          }
        case 'warning':
        default:
          return {
            titleColor: 'ap-yellow--text',
            iconColor: 'warning',
            icon: '$mdi-alert-circle-outline',
          }
      }
    },
  },
}
</script>

<template>
  <div
    class="d-flex align-center"
    :class="isSuggestionsVisible ? 'justify-space-between' : 'justify-end'"
  >
    <div v-if="isSuggestionsVisible">
      <div class="d-flex">
        <div class="text-uppercase mr-4">Address</div>
        <div class="text-caption ap-green--text d-flex align-center">
          <v-icon small color="green" class="mr-1"> $mdi-check </v-icon> Valid
        </div>
      </div>
      <div class="text-caption ap-dark-gray--text">
        Please choose which values to keep:
      </div>
    </div>
    <ApLogo feature="CleanData" />
  </div>
</template>

<script>
import ApLogo from '@/components/common/ApLogo'

export default {
  components: {
    ApLogo,
  },
  props: {
    isSuggestionsVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    class="d-flex flex-column align-content-center justify-center text-center ap-dark-gray--text"
  >
    <v-progress-circular indeterminate color="primary" class="mx-auto mb-4" />
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="text-body-2">
    <div>
      <div class="d-flex text-caption">
        <div class="flex-1" />
        <div class="flex-1">
          <span class="ap-light-grey ap-dark-gray--text pa-1">User entry</span>
        </div>
        <div class="flex-1">
          <span class="ap-light-grey ap-dark-gray--text pa-1"
            >System entry</span
          >
        </div>
      </div>

      <div
        v-for="prop in props"
        :key="prop.key"
        class="d-flex py-3 border-bottom"
      >
        <div class="flex-1 break-word">
          {{ prop.label }}
        </div>

        <v-radio-group
          v-model="finalRecord[prop.key]"
          class="pa-0 ma-0 flex-2"
          hide-details
        >
          <div class="d-flex">
            <div class="flex-1">
              <v-radio
                v-if="
                  systemEntry[prop.key] &&
                  userEntry[prop.key] !== systemEntry[prop.key]
                "
                :value="userEntry[prop.key]"
              >
                <template #label>
                  <span
                    class="text-body-2 font-weight-bold ap-black--text break-word"
                  >
                    {{ userEntry[prop.key] || emptyValuePlaceholder }}
                  </span>
                </template>
              </v-radio>
              <span
                v-else
                class="ap-black--text text-body-2 font-weight-bold break-word"
              >
                {{ userEntry[prop.key] || emptyValuePlaceholder }}
              </span>
            </div>

            <div class="flex-1">
              <v-radio
                v-if="
                  systemEntry[prop.key] &&
                  systemEntry[prop.key] !== userEntry[prop.key]
                "
                :value="systemEntry[prop.key]"
              >
                <template #label>
                  <span
                    class="text-body-2 font-weight-bold primary--text break-word"
                  >
                    {{ systemEntry[prop.key] || emptyValuePlaceholder }}
                  </span>
                </template>
              </v-radio>
              <span
                v-else
                class="primary--text text-body-2 font-weight-bold break-word"
              >
                {{ systemEntry[prop.key] || emptyValuePlaceholder }}
              </span>
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>

    <div class="d-flex justify-end mt-4">
      <v-btn outlined color="primary" @click="preSelectValues">
        <v-icon left> $mdi-refresh </v-icon>
        Reset
      </v-btn>

      <v-btn outlined color="primary" @click="$emit('cancel')"> Cancel </v-btn>

      <v-btn color="primary" class="mr-2" @click="$emit('submit', finalRecord)">
        Accept
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Array,
      required: true,
    },
    userEntry: {
      type: Object,
      default() {
        return {}
      },
    },
    systemEntry: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      emptyValuePlaceholder: '---',
      finalRecord: {},
    }
  },
  created() {
    this.preSelectValues()
  },
  methods: {
    preSelectValues() {
      this.finalRecord = { ...this.userEntry }

      for (const property in this.systemEntry) {
        if (this.systemEntry[property] && !this.userEntry[property]) {
          this.finalRecord[property] = this.systemEntry[property]
        }
      }
    },
  },
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.border-bottom {
  border-bottom: 1px solid var(--v-ap-grey-base);
}
</style>
